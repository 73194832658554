<template>
  <div>
    <top-nav-bar v-if="showGoBack"></top-nav-bar>
    <section id="map">
      <vl-map
        v-if="getShops"
        class=""
        :load-tiles-while-animating="true"
        :load-tiles-while-interacting="true"
        :move-tolerance="15"
      >
        <vl-view
          :zoom.sync="zoom"
          :center.sync="center"
          :rotation.sync="rotation"
        ></vl-view>

        <vl-layer-tile id="osm">
          <vl-source-osm></vl-source-osm>
        </vl-layer-tile>

        <vl-layer-vector v-for="shop in getShops" :key="shop.id">
          <vl-feature :id="shop.id">
            <vl-geom-point :coordinates="[shop.lng, shop.lat]"> </vl-geom-point>
            <vl-style-box>
              <vl-style-icon
                :src="markerSrc"
                :scale="markerScale"
                :anchor="[0.5, 1]"
                @click="showShop(shop.id)"
              ></vl-style-icon>
            </vl-style-box>
          </vl-feature>
        </vl-layer-vector>
        <vl-interaction-select
          @select="onSelectFeature"
          @unselect="onUnselectFeature"
        ></vl-interaction-select>
      </vl-map>
      <info-box
        :shop="shop"
        v-if="showInfobox"
        @close="closeInfobox"
      ></info-box>
    </section>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import InfoBox from "./InfoBox.vue";
import Vue from "vue";
import VueLayers from "vuelayers";
Vue.use(VueLayers, { dataProjection: "EPSG:4326" });
import "vuelayers/lib/style.css"; // needs css-loader
export default {
  components: { InfoBox },
  inject: ["activePage"],
  props: ["id"],
  data() {
    return {
      zoom: 8,
      center: [9.788468678169277, 54.136737556574566],
      rotation: 0,
      markerSrc: `${process.env.BASE_URL}assets/images/pin.svg`,
      shop: null,
      showInfobox: false,
      shopId: null,
      featureSelectEvent: null,
      showGoBack: false,
      markerScale: 0.6,
    };
  },
  computed: {
    ...mapGetters(["getShop", "getShops"]),
  },
  methods: {
    ...mapActions(["loadShop"]),
    async updateShop() {
      await this.loadShop(this.shopId);
      const shop = this.getShop;
      if (shop) {
        this.shop = shop;
      }
      console.log("shop is loaded");
    },
    async showShop(id) {
      this.shopId = id;
      await this.updateShop();
      this.showInfobox = true;
    },
    hideShop() {
      this.showInfobox = false;
    },
    async onSelectFeature(feature) {
      this.showShop(feature.id_);
    },
    onUnselectFeature() {
      this.hideShop();
    },
    closeInfobox() {
      this.showInfobox = false;
    },
  },
  watch: {
    zoom(nv) {
      if (nv < 11) {
        this.markerScale = 0.6;
      } else if (nv < 13) {
        this.markerScale = 1;
      } else {
        this.markerScale = 1.3;
      }
    },
  },
  async created() {
    //console.log(this.id);
    if (this.id) {
      this.shopId = this.id;
      await this.updateShop();
      this.center = [this.shop.lng, this.shop.lat];
      this.zoom = 14;
      this.showShop();
      this.showGoBack = true;
    }
    this.activePage.value = this.$routeNames.routeMapPage;
  },
};
</script>

<style scoped>
#map {
  padding: 0px !important;
  overflow: hidden;
  width: 100vw;
  height: calc(100vh - 60px);
}
@media only screen and (max-width: 1024px) {
  #map {
    padding: 0px !important;
    width: 100%;
  }
}
</style>
