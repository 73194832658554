<template>
  <div class="infobox col-11 col-md-6 col-lg-4">
    <div class="container p-3">
      <div class="row justify-content-end">
        <close-icon @click="close" class="close-icon text-end"></close-icon>
      </div>
      <h1 class="text-handwriting text-center">{{ shop.title }}</h1>
      <div class="row">
        <div class="col-4">
          <div
            class="img-container"
            :style="{ backgroundImage: `url('${shop.path}'` }"
          ></div>
        </div>
        <div class="col-8">
          <p class="text-libre fst-italic my-0">{{ shop.street }}</p>
          <p class="text-libre fst-italic my-0">
            {{ shop.postalCode }} {{ shop.city }}
          </p>
          <p class="text-libre fst-italic my-0">Tel: {{ shop.phone }}</p>
          <a
            class="text-libre fst-italic my-0 text-decoration-underline"
            :href="shop.link"
            v-show="shop.link"
            target="_blank"
            :aria-label="`Link zur Webseite von ${shop.title}`"
            >{{ shopLinkText }}</a
          >
        </div>
      </div>
      <p v-if="shop.description.length">
        {{ shopDescription }}
        <a
          class="text-uppercase text-red"
          v-show="!showShopDescriptionFullText"
          @click="showShopDescriptionFullText = !showShopDescriptionFullText"
          >mehr</a
        >
      </p>
    </div>
  </div>
</template>

<script>
import CloseIcon from "vue-material-design-icons/Close.vue";
export default {
  components: { CloseIcon },
  props: ["shop"],
  emits: ["close"],
  data() {
    return {
      showShopDescriptionFullText: false,
    };
  },
  computed: {
    shopDescription() {
      return this.showShopDescriptionFullText
        ? this.shop.description
        : this.shop.description.substr(0, 50) + "...";
    },
    shopLinkText() {
      let link = this.shop.link;
      if (link.includes("http:")) {
        link = link.replace("http://", "");
      }

      if (link.includes("https:")) {
        link = link.replace("https://", "");
      }

      if (link.includes("www")) {
        link = link.replace("www.", "");
      }

      let arr = link.split("/");
      let newLink = arr[0];
      return newLink;
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
.infobox {
  background-color: white;
  position: fixed;
  margin-left: 50%;
  transform: translateX(-50%);
  bottom: 70px;
  border: dotted 3px;
  border-radius: 15px;
}

.infobox .text-libre {
  letter-spacing: 1px;
}

p,
a {
  font-size: 0.9rem;
}

.close-icon {
  xposition: absolute;
  xtop: 10px;
  xright: 10px;
  padding: 5px 10px;
  border-radius: 25px;
  background: white;
}

.img-container {
  margin: auto;
  height: 100%;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
</style>
